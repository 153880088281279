window.headerSticky = {

  init(header, headerHeight) {
    const _this = this

    this.headerHeight = headerHeight
    this.isFixed = ($(header).data('fixed') == false) ? false : true
    this.events()
    this.headerSticky($(header), this.isFixed)
  },

  getRoot() {
    return document.querySelector(':root')
  },

  events() {
    const _this = this,
      $rootHtml = _this.getRoot()

    let scrollPos = $(window).scrollTop(),
      sideNavMob = $(document).find('.js-mob-side')

    $rootHtml.style.setProperty('--window-height', window.innerHeight + 'px')
    $(document).find('.page-intro').addClass('page-intro_page_load')
    $(document).find('#js-intro-main').addClass('intro-3d__container_page_load')

    $(window).on('scroll touchmove', function () {
      let scrollCur = $(this).scrollTop()

      if (scrollCur > scrollPos)
      {
        $(sideNavMob).addClass('mobile-menu_header_out')
      }
      else
      {
        // На ios уходит в минус
        if (scrollCur < 30) scrollCur = 0
        if (scrollCur == 0) {
          $(sideNavMob).removeClass('mobile-menu_header_out')
        }
      }
    })

    $(document).on('click', '.js-search-header-show', function () {
      const $mobileMenu = $(document).find('.js-mob-side')
      $('.js-search-header').addClass('active')
      $(this).addClass('hide')

      if ($mobileMenu.hasClass('active')) {
        $('.js-menu-toggle').trigger('click')
      }

      let $inputSearch = $('.js-search-header').find('.form-search__input')
      $inputSearch.focus()

      $('.js-nav-toggle-item').css('opacity', 0)

      if (window.innerWidth <= 767) {
        $('html, body').css('overflow-y', 'hidden')
        $('.js-header').find('.user-menu').addClass('hide')
        $('.header__logo').addClass('hide')
        $('.nav_header').addClass('hide')
      }
      else {
        $('.js-header').addClass('header_fixed')
      }
    })

    $(document).on('click', '.js-search-header-hide', function () {
      $('.js-search-header').removeClass('active')
      $('.js-search-header-show').removeClass('hide')
      $('.js-nav-toggle-item').css('opacity', 1)

      if (window.innerWidth <= 767) {
        $('html, body').css('overflow-y', 'initial')
        $('.js-header').find('.user-menu').removeClass('hide')
        $('.header__logo').removeClass('hide')
        $('.nav_header').removeClass('hide')
      }
    })
  },

  headerSticky(header, isFixed) {
    if (isFixed)
    {
      const _this = this,
        $rootHtml = this.getRoot()

      let scrollPos = $(window).scrollTop(),
        scrollElement = $(document).find('.js-scroll-el'),
        sideNavMob = $(document).find('.js-mob-side')

      header.children().wrapAll('<div class="header__fixed"></div>')

      if (scrollPos > 0) {
        $rootHtml.style.setProperty('--header-fixed-height', "100%")
        header.addClass('header_fixed')
      }

      $(window).on('scroll', function (e) {
        let scrollCur = $(this).scrollTop()

        if (scrollCur > scrollPos) {
          $rootHtml.style.setProperty('--header-fixed-height', "100%")
          $(scrollElement).removeClass('section-scroll__get-service_state_down')
          $(sideNavMob).addClass('mobile-menu_header_out')

          if ($('.js-dropdown').hasClass('active'))
          {
            $('.js-dropdown').removeClass('active')
          }

          if (scrollCur > _this.headerHeight) {
            if (!$('.js-search-result-popup').length > 0 && !$('.js-search-header').hasClass('active')) {
              header
                .addClass('header_fixed')
                .removeClass('header_show')
                .addClass('header_hide')
            }
          }
        }
        else {

          if (!$('.js-search-result-popup').length > 0 && !$('.js-search-header').hasClass('active')) {
            header.removeClass('header_hide').addClass('header_show')
          }

          if ($(scrollElement).hasClass('section-scroll__get-service_state_fixed'))
            $(scrollElement).addClass('section-scroll__get-service_state_down')
          else
            $(scrollElement).removeClass('section-scroll__get-service_state_down')

          if (header.hasClass('header_show')) {
            $(sideNavMob).removeClass('mobile-menu_header_out')
          } else {
            $(sideNavMob).addClass('mobile-menu_header_out')
          }

          // На ios уходит в минус
          if (scrollCur < 0) scrollCur = 0
          setTimeout(() => {
            if (scrollCur == 0) {
              $rootHtml.style.setProperty('--header-fixed-height', "0")
              $(sideNavMob).removeClass('mobile-menu_header_out')
              header.removeClass('header_fixed')
              if (!$('.js-search-result-popup').length > 0 && !$('.js-search-header').hasClass('active')) {
                header.removeClass('header_show')
              }
            }
          }, 0)
        }

        if (window.innerWidth <= 767 && !$('.js-search-header').hasClass('active')) {
          $('.user-menu').removeClass('hide')
          $('.header__logo').removeClass('hide')
          $('.nav_header').removeClass('hide')
        }

        scrollPos = scrollCur
      })
    }
  }
}
